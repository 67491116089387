import { format, parse } from 'date-fns';

// Types
import { DateFormat } from './types';

import { getDateFormat } from './getDateFormat';

/**
 * TODO: Add explanation
 * @param formatStr
 */
export function formatCurrentDate(formatStr?: DateFormat): string {
  const now = new Date().toISOString();
  const ISO8601Format = "yyyy-MM-dd'T'HH:mm:ss.SSSX";
  const parsedDate = parse(now, ISO8601Format, new Date());
  return format(parsedDate, getDateFormat(formatStr));
}
