export interface DateOptions {
  formatInUTC?: boolean;
}

/**
 * 'default': '10/24/2018 06:43PM'
 * 'minutes': '10/24/2018 06:43PM'
 * 'days': '10/24/2018'
 * 'year': '2018'
 * 'shortMonth': 'Oct 24, 2018'
 * 'seconds': 'Oct 24, 2018 16:43:41'
 * 'longMinutes': 'Oct 24, 2018, 4:43pm'
 * 'businessDays': '2018-10-24'
 * 'seconds12h': 'Oct 24, 2018 4:43:41 PM'
 */
export type DateFormat =
  | 'default'
  | 'minutes'
  | 'days'
  | 'year'
  | 'shortMonth'
  | 'seconds'
  | 'longMinutes'
  | 'businessDays'
  | 'seconds12h';

export enum DATE_UNITS {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}
