/**
 * TODO: Add explanation
 *
 * @disclaimer Currently, date-fns doesn't natively support UTC. A workaround if we want
 * to display the time in UTC is to add/subtract the appropriate number
 * of milliseconds difference between UTC time. This function takes in
 * a local timestamp converted to milliseconds, and then converts it to a UTC
 * time.
 * @param localTimestampMillis
 */
export function getUTCDateMillis(localTimestampMillis: number): number {
  const offsetMinutes = new Date().getTimezoneOffset();
  // Turn minutes to millis
  const offsetMillis = offsetMinutes * 60 * 1000;
  return offsetMillis + localTimestampMillis;
}
